@import '../vars.sass'

.Top
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  text-align: center
  width: 100%
  background-color: #FFF
  position: relative
  &-burger
    line-height: 0
  &-logo-lldd
    display: block
    width: 60px
    height: 60px
    background-image: url('../../static/logo.png')
    background-position: 0 0
    cursor: pointer
    &:hover
      background-position: 0 -60px
  &-main-menu
    margin-left: -30%
  &-menu
    margin: 0
    padding: 0
    > li
      list-style-type: none
      display: inline-block
      vertical-align: middle
      font-size: 14px
      > a
        display: block
        padding: 12px 35px
        font-weight: bold
        &:hover
          text-decoration: none
          color: $black
  &-lldd
    a
      color: $yellow
    .text-yellow
      color: $yellow
      margin: 0
    &-container
      display: none
      position: absolute
      top: 64px
      left: 0
      right: 303px
      z-index: 10
      text-align: left
      padding-top: 5px
      &-wrap
        background: #FFF
        padding: $space / 1.3
    &:hover &-container
      display: block
    &-grid
      display: grid
      grid-template-columns: repeat(6, 1fr)
      justify-items: start
    &-itema, &-itemb
      margin: 25px 0
    &-itemb
      grid-column: 2 / 7
    &-list
      display: block
      padding: 0
      li
        list-style-type: none
        a
          display: block
          padding: 4px 0
          color: $font
          &:hover
            text-decoration: none
            color: $yellow
    &-link
      color: $yellow
      font-weight: bold
      &:hover
        text-decoration: none
        color: $black
      &.--red
        color: $red
        &:hover
          text-decoration: none
          color: $black
  &-series
    a
      color: $series
    &-container
      display: none
      position: absolute
      top: 64px
      left: 0
      right: 303px
      z-index: 10
      text-align: left
      padding-top: 5px
      &-wrap
        background: #FFF
        padding: $space / 1.3
    &:hover &-container
      display: block
    &-grid
      display: grid
      grid-template-columns: repeat(5, 1fr)
      justify-items: start
    &-itema, &-itemb
      margin: 25px 0
    &-itemb
      grid-column: 2 / 6
    &-itemc p, &-itemc a
      color: $zipaquira
      margin: 0
    &-itemd p, &-itemd a
      color: $manizales
      margin: 0
    &-iteme p, &-iteme a
      color: $caldas
      margin: 0
    &-itemf p, &-itemf a
      color: $cali
      margin: 0
    &-list
      display: block
      padding: 0
      li
        list-style-type: none
        a
          display: block
          padding: 4px 0
          &:hover
            text-decoration: none
            color: $black
    &-link
      color: $home
      font-weight: bold
      &:hover
        text-decoration: none
        color: $black
  &-adventures
    a
      color: $home
    &-container
      display: none
      position: absolute
      top: 64px
      left: 0
      right: 303px
      z-index: 10
      text-align: left
      padding-top: 5px
      &-wrap
        background: #FFF
        padding: $space / 1.3
    &:hover &-container
      display: block
    &-grid
      display: grid
      grid-template-columns: repeat(4, 210px)
      justify-items: start
    &-itema, &-itemb, &-itemc, &-itemd
      margin: 25px 0
    &-iteme p
      color: $cafe
      margin: 0
      span
        display: block
    &-itemf p
      color: $light-blue
      margin: 0
      span
        display: block
    &-itemg
      grid-column: 3 / 5
      p
        color: $orange_series
        margin: 0
        span
          display: block
    &-list
      display: block
      padding: 0
      li
        list-style-type: none
        a
          display: block
          padding: 4px 0
          color: $font
      &.--coffee
        li a:hover
          text-decoration: none
          color: $cafe
      &.--antioquia
        li a:hover
          text-decoration: none
          color: $light-blue
      &.--volcano
        li a:hover
          text-decoration: none
          color: $orange_series
    &-link
      color: $home
      font-weight: bold
      &:hover
        text-decoration: none
        color: $black

  &-caribe
    a
      color: $caribe
    &-container
      display: none
      position: absolute
      top: 64px
      left: 0
      right: 303px
      z-index: 10
      text-align: left
      padding-top: 5px
      &-wrap
        background: #FFF
        padding: $space / 1.3
    &:hover &-container
      display: block
    &-grid
      display: grid
      grid-template-columns: repeat(5, 1fr)
      justify-items: start
    &-itema, &-itemb
      margin: 25px 0
    &-itemb
      grid-column: 2 / 6
    &-itemc p
      color: $caribe
    &-itemc a
      color: $font
      margin: 0
    &-list
      display: block
      padding: 0
      li
        list-style-type: none
        a
          display: block
          padding: 4px 0
          &:hover
            text-decoration: none
            color: $caribe
    &-link
      font-weight: bold
      &:hover
        text-decoration: none
        color: $black

@media screen and ( max-width: $phone )
  .Top
    padding: 5px 10px
    &-logo-lldd
      display: block
      width: 40px
      height: 40px
      background-image: url('../../static/logo.png')
      background-position: 0 0
      background-size: cover
      cursor: pointer
      &:hover
        background-position: 0 -40px

@media screen and ( max-width: $tablet )
  .Top
    padding: 5px 10px
