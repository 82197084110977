@import '../vars'

.news-container
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 20px
  margin: 40px 0 10px 0

  &__item
    overflow: hidden
    position: relative

    &:first-child
      grid-column: span 2
      grid-row: span 2
      font-size: 30px
    
    .n-item
      &__img
        width: 100%
        transition: 0.5s
        -webkit-transition: 0.5s

        &:hover
          -webkit-transform: scale(1.1)
          transform: scale(1.1)
          transition: 0.5s
          -webkit-transition: 0.5s
      
      &__text
        position: absolute
        width: 100%
        left: 0
        bottom: 0
        padding: 8px 10px 0 10px
        background-color: rgba(0,0,0,0.6)
        font-weight: bold
        -moz-osx-font-smoothing: grayscale
        color: #FFF
        line-height: 1.2

        p
          margin: 0

        &--tag
          color: $yellow
          text-transform: uppercase

        &--date
          color: $orange

.homeNews-load-more
  margin: 10px 0 40px 0
  font-size: 20px
  cursor: pointer

.News-load-more
  margin: 10px 0 40px 0
  font-size: 20px
  cursor: pointer
  color: #000
  background-color: #efcf30
  font-weigh: bold
  padding: 6px 10px
  text-transform: uppercase
  border: none
  font-weight: bold
  &:hover
    background-color: #000
    color: #fff

@media screen and ( max-width: $phone )
  .news-container
    grid-template-columns: repeat(1, 1fr)

    &__item
      &:first-child
        grid-column: span 1
        grid-row: span 1
        font-size: 14px