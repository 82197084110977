@import '../vars.sass'

.banner
  padding: 0
  box-sizing: border-box
  border-top: 5px solid #f1d130
  border-bottom: 5px solid #f1d130
  position: relative
  overflow: hidden

  &__actions
    font-family: 'helveticaneuebold'
    position: absolute
    top: 0
    right: 0
    width: 300px
    box-sizing: border-box

    figure
      background-color: rgba(255, 255, 255, 0.9)
      padding: 0 18px
      margin-bottom: 3px

      img
        width: 100%
    
    p
      margin: 0
      padding: 8px 0
      text-align: center

      &:nth-child(2), &:nth-child(4)
        background-color: rgba(241, 209, 48, .9)
        color: $black
        margin-bottom: 3px

      &:nth-child(3)
        background-color: rgba(0, 0, 0, .9)
        color: $white
        margin-bottom: 3px

      &:nth-child(6)
        padding: 0
        margin-bottom: 3px

      &:nth-child(5), &:nth-child(7)
        padding: 0

    a
      display: block
      padding: 8px 0
      text-align: center

      &:hover
        background-color: $black
        color: $white
        text-decoration: none

    &--register
      background-color: rgba(237, 21, 36, .9)
      color: $white
      margin-bottom: 3px

    &--whatsapp
      background-color: rgba(81, 185, 99, .9)
      color: $white
    
    &--newsletter
      display: block
      width: 100%
      padding: 8px 0
      text-align: center
      border: none
      background: #FF9300
      color: $white

      &:hover
        background-color: $black
        text-decoration: none

@media screen and ( max-width: $desktop )
  .banner
    &__actions
      figure
        text-align: center
        img
          width: 70%
      p
        padding: 4px 0
      a
        padding: 4px 0
      button
        padding: 4px 0

@media screen and ( max-width: $tablet )
  .banner
    &__actions
      width: 240px
      font-size: 12px
      figure
        img
          width: 65%
      p
        padding: 3px 0
      a
        padding: 3px 0
      button
        padding: 3px 0

@media screen and ( max-width: $phone )
  .banner
    &__actions
      width: 230px
      font-size: 11px
      figure
        img
          width: 35%

@media screen and ( max-width: $sm_phone )
  .banner
    &__actions
      width: 160px
      font-size: 7px
      figure
        img
          width: 25%