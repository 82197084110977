@import "../vars.sass"

h1
    font-weight: bold
    -moz-osx-font-smoothing: grayscale
    font-size: 36px

.leaders
    width: 100%
    margin: 0 0 10px 0
    padding: 0
    display: flex
    justify-content: space-between

    &__item
        list-style-type: none
        width: calc( 100% / 7 )
        padding: 30px 5px 0 5px
        box-sizing: border-box
        text-align: center

        &--title
            margin: 10px 0 20px 0
        
        img
            width: 100%

@media screen and ( max-width: $desktop )
    .leaders
        flex-wrap: wrap
        justify-content: center

        &__item
            width: calc( 100% / 4 )
            margin-bottom: 10px
    
            p
                margin: 0
            
            &--title
                b
                    display: block
                    margin-bottom: 7px

@media screen and ( max-width: $phone )
    h1
        font-size: 25px

    .leaders
        flex-wrap: wrap
        justify-content: center

        &__item
            width: calc( 100% / 2 )
            margin-bottom: 10px
    
            p
                margin: 0
            
            &--title
                b
                    display: block
                    margin-bottom: 7px
    