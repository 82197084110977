@import '../vars.sass'

.home-prices
  h1
    margin: 40px 0 20px 0
    line-height: 1
    text-align: center

.prices-grid
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 40px
  margin-bottom: 40px

  &__description
    background-color: $dark
    color: white
    text-align: center
    padding: 15px
    box-sizing: border-box

    &--price
      font-size: 13px
      b
        display: block
      span
        display: block
        color: $green
        margin-bottom: 5px

    &--close
      color: $orange
      font-size: 24px

      img
        width: 100%
.--promotions
  .prices-grid
    grid-template-columns: repeat(4, 1fr)

.price-gallery
  margin-bottom: 40px

.payment-form-prices
  display: block
  width: 100%
  display: flex
  flex-direction: row
  padding: 40px 0

  &__container
    display: block
    width: calc(100% / 4 - 30px)

    &:first-child
      margin-right: 40px

    span
      display: block
      margin-bottom: 15px
      text-align: center

    input
      width: 100%
      border: none
      padding: 7px
      color: $black
      text-align: center

      &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active
        -webkit-box-shadow: 0 0 0px 1000px $white inset
        -webkit-text-fill-color: $black

    select
      width: 100%
      text-align-last: center
      border: none
      border: none
      padding: 8px
      color: $black

  &__icon
    display: flex
    justify-content: center
    align-items: center
    width: 36px
    height: 34px
    font-size: 26px
    margin: 36px 2px 0 2px

  &__button
    display: flex
    justify-content: center
    align-items: center
    width: 32px
    height: 34px
    font-size: 20px
    margin: 35px 5px 0 5px

    button
      width: 100%
      height: 34px
      background-color: $yellow
      border: none
      color: black
      font-size: 34px
      line-height: 1

      &:hover
        background-color: $black
        color: $white

@media screen and ( max-width: $desktop )
  .prices-grid__description
    &--close
      font-size: 20px

@media screen and ( max-width: $tablet )
  .prices-grid
    grid-template-columns: repeat(1, 1fr)
    &__description
      &--close
        img
          width: 40%

@media screen and ( max-width: $phone )
  .payment-form-prices
    flex-wrap: wrap

    &__container
      width: 100%
      margin-bottom: 20px

      &:first-child
        margin-right: 0px

    &__icon
      width: 100%
      height: auto
      margin: 0 0 20px 0

    &__button
      width: 100%
      height: auto
      margin: 0 0 20px 0
