@import '../vars.sass'

.contact-form-partner
  margin: 40px 0

  input
    background-color: $yellow
    border: none
    width: 100%
    color: black
    margin-bottom: 15px
    padding: 10px

    &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active
      -webkit-box-shadow: 0 0 0px 1000px $yellow inset
      -webkit-text-fill-color: $black

  ::placeholder
    color: $black

  :-ms-input-placeholder
    color: $black

  ::-ms-input-placeholder
    color: $black

  select
    background-color: $yellow
    border: none
    width: 100%
    color: black
    margin-bottom: 15px
    padding: 10px

  textarea
    background-color: $yellow
    border: none
    width: 100%
    color: black
    margin-bottom: 15px
    padding: 10px

  button
    display: block
    width: 100%
    background-color: #000
    color: #FFF
    border: none
    margin-bottom: 15px
    padding: 10px

    &:hover
      background-color: $yellow
      color: #000
      
    &.btn-thanks
      background: $whatsapp

  .btn-fill
    background-color: $red
    color: $white

    &:hover
      background-color: $red
      color: $white

    &::placeholder
      color: $white

    &:-ms-input-placeholder
      color: $white

    &::-ms-input-placeholder
      color: $white
