h1
  margin: 40px 0 0 0

.simple-section__banner
  border-top: 5px solid #f1d130
  border-bottom: 5px solid #f1d130

.post-race
  tr
    border-bottom: 15px solid #FFF

    td
      padding: 10px

  .table-yellow
    font-weight: bold
    text-align: center
    background-color: rgba(239,207,48,0.7)

  .table-grey
    background-color: rgba(214, 214, 214, 0.5)

.table_daily 
  tr
    th
      background-color: #000
      color: #FFF

    td
      vertical-align: middle

      span
        font-size: 18px

    &:first-child
      font-weight: bold

    &:nth-child(odd)
      background-color: #d6d6d6

    &.back_yellow
      background-color: rgba(239,207,48,0.7)
      font-weight: bold

      td
        vertical-align: middle

.training-plans
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-gap: 10px