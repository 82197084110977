@import '../vars.sass'

.jaguares
  &__title
    margin: 40px 0

  &__banner
    border-top: 5px solid #f1d130
    border-bottom: 5px solid #f1d130

.jaguares-gallery
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-gap: 45px
  margin: 40px 0
  &__item 
    a
      display: block
      position: relative
      img
        width: 100%
      span
        display: none
        position: absolute
        width: 100%
        height: 100%
        top: 0
        left: 0
        background-color: rgba(241, 209, 48, 0.7)
        justify-content: center
        align-items: center
        color: black
        font-weight: bold
        font-size: 60px
      &:hover span
        display: flex
    &--info
      display: flex
      flex-direction: row
      background-color: #424242
      color: #EBEBEB
      box-sizing: border-box
      padding: 4px 5px
      justify-content: space-between
      align-items: center

.jaguares-info
  width: 80%
  box-sizing: border-box
  text-transform: uppercase
  line-height: 1.2
  &__name
    display: block
    font-size: 13px
  &__surname
    display: block
    font-size: 17px
    font-weight: bold

.jaguares-country
  width: 17%
  box-sizing: border-box
  img
    object-fit: contain
    width: 100%

.jaguares-modal
  display: none
  justify-content: center
  align-items: center
  position: fixed
  left: 0
  top: 0
  width: 100vw
  height: 100vh
  background-color: rgba(0, 0, 0, .8)
  z-index: 999
  overflow-y: auto
  &__wraper
    display: block
    width: 80%
    max-width: 1000px
    height: auto
    text-align: right
    &--btn
      border: 0
      background: transparent
      outline: none
      svg
        fill: rgba(239,207,48,0.7)
        height: 29px
        margin-top: 2px
      &:hover
        svg
          fill: rgba(239,207,48,1)
    &--content
      text-align: left
      background-color: #EBEBEB
      padding: 20px
  &__nav
    position: absolute
    width: 100%
    z-index: 1200
    ul
      margin: 0
      padding: 0
      display: flex
      width: 100%
      justify-content: space-between
      li
        list-style-type: none
        padding: 0 15px
        &:nth-child(2)
          text-align: right
        a 
          img
            opacity: 0.65
        &:hover
          img
            opacity: 1

.jaguares-content
  display: grid
  grid-template-columns: 2fr 1fr
  color: #424242
  &__foto img
    width: 100%
  &__info
    padding: 0 20px
    box-sizing: border-box
    display: flex
    flex-direction: column
    justify-content: space-between
    &--item
      p
        margin: 0
      strong
        display: block
        font-weight: bold
        font-size: 16px
      &:nth-last-child(1) strong
        margin-bottom: 0
  &__data
    grid-column: span 2
    display: flex
    flex-direction: row
    flex-wrap: wrap
    margin-top: 10px
    &--number
      width: 10%
      display: flex
      justify-content: center
      align-items: center
      background-color: #424242
      color: #F1D130
      font-weight: bold
      font-size: 40px
    &--flag
      width: 10%
      margin: 0 1.2%
      img
        width: 100%
    &--name
      width: 77%
      display: flex
      align-items: center
      font-size: 40px
    &--comment
      width: 100%

@media screen and (max-width: 992px)
  .jaguares-gallery
    grid-template-columns: repeat(3, 1fr)
  .jaguares-content__foto
    grid-column: span 2
  .jaguares-content__info
    grid-column: span 2
    padding: 10px 0
  .jaguares-content__data--name
    font-size: 29px
  .jaguares-content__data--number
    font-size: 20px
  .gallery-modal__nav
    position: fixed
    top: 50%
    transform: translateY(-35px)
  .jaguares-modal
    align-items: flex-start

@media screen and (max-width: 768px)
  .jaguares-content__data--number
    font-size: 14px
  .jaguares-content__data--name
    font-size: 21px
    line-height: 1
  .jaguares-content__data--flag
    line-height: 0
  .jaguares-gallery
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 20px

@media screen and (max-width: 520px)
  .jaguares-gallery
    grid-template-columns: repeat(1, 1fr)
    grid-gap: 20px
  .jaguares-content__data
    align-items: flex-start