@import '../vars.sass'

.sponsors-partner
  &__banner
    border-top: 5px solid #f1d130
    border-bottom: 5px solid #f1d130

  &__spo
    margin-bottom: 20px
    position: relative
    background-color: #EBEBEB

    > li
      width: 24%
      min-height: 100px
      padding: 15px 0
      text-align: center
      vertical-align: top
      cursor: pointer

    &--descriptionspo
      position: absolute
      top: auto
      left: 0
      width: 100%
      background-color: #424242
      color: #FFF
      display: none
      padding: 25px
      margin-top: 15px

  &__hg
    display: block

  &__cerrar
    position: absolute
    right: 20px
    top: 0px
    z-index: 9999
    display: block
    width: 20px
    height: 20px
    &:hover
      color: $yellow

.social-n
  li img
    max-width: 30px