@import "../vars.sass"

.home-categories-title
  margin: 40px 0
  p
    margin: 0

.home-categories-grid
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-gap: 40px
  margin-bottom: 40px

  > div
    background-color: $dark
    color: white

    span
      display: block
      width: 100%
      padding: 7px 0 5px 0
      background-color: $yellow
      color: $dark
      text-align: center
      font-size: 15px
    
    div
      padding: 15px

      img
        width: 100%

@media screen and ( max-width: $desktop )
  .home-categories-grid
    grid-template-columns: repeat(3, 1fr)

@media screen and ( max-width: $tablet )
  .home-categories-grid
    grid-template-columns: repeat(2, 1fr)

@media screen and ( max-width: $phone )
  .home-categories-grid
    grid-template-columns: repeat(1, 1fr)