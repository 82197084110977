@import '../vars.sass'

.video-section
    margin: 40px 0

    h2
        font-size: 24px
        margin: 0 0 10px 0

    div
        margin-bottom: 30px