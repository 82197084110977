@import "../vars.sass"

.galeria_noticias
  display: block
  float: left
  width: 100%
  margin: 15px 0
  div
    float: left
  .img1
    margin: 0 0.9% 0 0
    width: 65.6%
    float: left
  .img2
    margin: 0 0 0.8% 0.9%
    width: 32.3%
    float: left
  .img3
    margin: 0 0 0 0.9%
    width: 32.3%
    float: left
    .counter_fotos
      font-family: 'helveticaneuemedium'
      font-weight: bold
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-color: rgba(0,0,0,0.6)
      font-size: 45px
      color: #FFF
      z-index: 2
      display: flex
      justify-content: center
      flex-direction: column
      text-align: center
  &--button
    border: none
    position: relative
    padding: 0
    background: transparent
  &__modal-title
    margin: 0
    color: $white
  &__modal-subtitle
    margin: 0
    font-size: 12px
    color: $white
