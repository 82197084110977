@import "../vars.sass"

.carousel-container
  margin: 0
  padding: 0
  display: block
  width: 100%
  overflow: hidden
  position: relative

  &__list
    margin: 0
    padding: 0
    list-style-type: none
    display: flex
    flex-direction: row
    justify-content: flex-start

    &--item
      flex-basis: calc( 100vw / 3 )
      position: relative

      button
        border: none
        background: transparent
        padding: 0

      img
        width: calc( 100vw / 3 )

      span
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background-color: rgba(227,195,66,0.6)
        display: none
        z-index: 10
    
      &:hover button span
        display: block

    &--left
      position: absolute
      width: 50px
      left: 15px
      top: 50%
      transform: translateY(-25px)
      z-index: 20

    &--right
      position: absolute
      width: 50px
      right: 15px
      top: 50%
      transform: translateY(-25px)
      z-index: 20

.carousel-modal
  display: flex
  justify-content: center
  align-items: center
  position: fixed
  left: 0
  top: 0
  width: 100vw
  height: 100vh
  background-color: rgba(0, 0, 0, .8)
  z-index: 999
  
  &__wraper
    display: block
    width: 80%
    max-width: 1000px
    height: auto
    text-align: right

    &--btn
      border: 0
      background: transparent
      outline: none

      svg
        fill: rgba(239,207,48,0.7)
        height: 29px
        margin-top: 2px
      &:hover
        svg
          fill: rgba(239,207,48,1)

    &--content
      text-align: left

      img
        width: 100%

  &__nav
    position: absolute
    width: 100%
    z-index: 1200

    ul
      margin: 0
      padding: 0

      li
        list-style-type: none
        display: inline-block
        width: 50%
        padding: 0 15px

        &:nth-child(2)
          text-align: right
        
        a
          img
            opacity: 0.65
        &:hover
          img
            opacity: 1

@media screen and ( max-width: $phone )
  .carousel-container__list--item
    button
      img
        width: 100vw