@import "../vars.sass"

.home-menu
  background-color: black

  &__list
    padding: 0
    margin: 0
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center

    &--item
      width: calc(100% / 7)
      padding: 6px 15px
      list-style-type: none
      text-align: center

      a
        font-family: 'helveticaneuebold'
        color: white

        &:hover
          color: $yellow
          text-decoration: none
        
        &:focus
          color: $yellow
          text-decoration: none

        &.active
          color: $yellow
          text-decoration: none

@media screen and ( max-width: $desktop )
  .home-menu
    &__list
      &--item
        padding: 6px 10px

@media screen and ( max-width: $tablet )
  .home-menu
    &__list
      flex-wrap: wrap
      justify-content: flex-start 

      &--item
        width: 33%

@media screen and ( max-width: $phone )
  .home-menu
    &__list
      &--item
        width: 50%