@import '../vars.sass'

.gallery-detail
  &__title
    margin: 40px 0 20px 0
    text-transform: capitalize

  &__banner
    border-top: 5px solid #f1d130
    border-bottom: 5px solid #f1d130

  &__list
    margin: 0
    padding: 0
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: space-between
    margin-bottom: 30px

    li
      list-style-type: none
      width: 24%
      margin-bottom: 1.5%
      position: relative
      cursor: pointer

      &:hover span
        display: block

      span
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background-color: rgba(227,195,66,0.6)
        display: none