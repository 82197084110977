.content-instagram
  position: relative
  width: 100%
  margin-bottom: 30px
  background-color: #fff
  padding: 10px
  border-radius: 10px
  border: 1px solid #efefef

  &__description

  &__img
    width: 100%
    margin-bottom: 15px

  &__back
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    border-radius: 10px
    background-color: rgba(227, 195, 66, 0.6)
    display: none
  
  &:hover &__back
    display: block

.mr-5
  margin-right: 5px

.Social
  margin-bottom: 40px
  h1
    margin-bottom: 30px