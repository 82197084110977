@import "../vars.sass"

.Footer
  background: black
  color: #FFF
  padding: 0
  display: grid
  padding: 10px 15px
  grid-template-columns: 1fr 160px
  align-content: center
  &::before
    display: none
  &-legal
    margin-top: 6px
    text-align: center
    p
      margin: 0
    &::before
      content: none
    &::after
      content: none
  &-link
    color: $white
    margin: 0 5px
    &:hover
      color: $yellow
      text-decoration: none
    &:focus
      color: $yellow
  &-redes
    .redes-menu
      margin: 0
      padding: 0
      display: inline-block
      vertical-align: middle

      li
          list-style-type: none
          display: inline-block
          padding: 0 2px
      a
          display: block
      
      span
          display: block
          width: 28px
          height: 28px
          background-size: contain

      &__fb
          background-image: url('../../static/redes/FB_blanco.png')
          &:hover
              background-image: url('../../static/redes/FB_color.png')

      &__tw
          background-image: url('../../static/redes/Twitter_blanco.png')
          &:hover
              background-image: url('../../static/redes/Twitter_color.png')

      &__in
          background-image: url('../../static/redes/IG_blanco.png')
          &:hover
              background-image: url('../../static/redes/IG_color.png')

      &__yt
          background-image: url('../../static/redes/Youtube_blanco.png')
          &:hover
              background-image: url('../../static/redes/Youtube_color.png')

      &__lk
          background-image: url('../../static/redes/linkedin_blanco.png')
          &:hover
              background-image: url('../../static/redes/linkedin_color.png')

@media screen and ( max-width: $tablet )
  .Footer
    display: block
    &-legal
      display: block
      width: 100%
    &-redes
      display: block
      width: 100%
      margin-top: 5px
      text-align: center