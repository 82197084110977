@import "../vars.sass"

.home-payment-title
  margin: 40px 0
  p
    margin: 0

.home-payment-grid
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-gap: 40px
  margin-bottom: 40px

  > div
    padding: 15px
    background-color: $dark
    color: $white

    h4
      text-align: center

.payment-form
  display: block
  width: 100%
  display: flex
  flex-direction: row
  padding: 40px 0

  &__container
    display: block
    width: calc(100% / 4 - 30px)

    &:first-child
      margin-right: 40px

    &:nth-child(2)
      margin-right: 40px

    span
      display: block
      margin-bottom: 15px
      text-align: center

    input
      width: 100%
      border: none
      padding: 7px
      color: $black
      text-align: center

      &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active
      -webkit-box-shadow: 0 0 0px 1000px $white inset
      -webkit-text-fill-color: $black

    select
      width: 100%
      text-align-last: center
      border: none
      border: none
      padding: 8px
      color: $black

  &__button
    display: flex
    justify-content: center
    align-items: center
    width: 32px
    height: 34px
    font-size: 20px
    margin: 35px 5px 0 5px

    button
      width: 100%
      height: 34px
      background-color: $yellow
      border: none
      color: black
      font-size: 34px
      line-height: 1

      &:hover
        background-color: $black
        color: $white

@media screen and ( max-width: $tablet )
  .home-payment-grid
    grid-template-columns: repeat(1, 1fr)

@media screen and ( max-width: $phone )
  .payment-form
    flex-wrap: wrap

    &__container
      width: 100%
      margin-bottom: 20px
      
      &:first-child
        margin-right: 0px

      &:nth-child(2)
        margin-right: 0px

    &__button
      width: 100%
      height: auto
      margin: 0 0 20px 0