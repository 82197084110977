@import "../vars.sass"

.main-results
  margin-bottom: 40px

  &__title
    margin-bottom: 40px

  h1, h3
    text-transform: uppercase

.results-form
  margin-top: 20px

  &__btn
    border: none
    background-color: #F1D130
    color:#000
    padding: 6px 12px

    &:hover
      background-color: black
      color: #FFF

  .form-group
    margin-bottom: 0px

    input, select
      border-radius: 0px

.form-margin
  margin-top: 20px

@media screen and ( max-width: $desktop )
  .form-group
    input, select
      margin-bottom: 10px