@import "../vars.sass"
    
.internal-menu
    background-color: $yellow

    &__list
        padding: 0
        margin: 0
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center

        &--item
            width: calc(100% / 7)
            list-style-type: none
            text-align: center
            padding: 10px 20px
            box-sizing: border-box
            
            p
                font-family: 'helveticaneuebold'
                margin-bottom: 0
                line-height: 1

        .internal-menu-list
            span
                display: block
                width: 86px
                height: 86px
                margin: 0 auto 10px auto
                background-size: cover
                background-position: top center

            &__stage-info
                background-image: url('../../static/icons/stage-info.png')
            
            &__spectator-info
                background-image: url('../../static/icons/spectator-info.png')
            
            &__news
                background-image: url('../../static/icons/news.png')
            
            &__results
                background-image: url('../../static/icons/results.png')
            
            &__videos
                background-image: url('../../static/icons/videos.png')
            
            &__gallery
                background-image: url('../../static/icons/gallery.png')
            
            &__social
                background-image: url('../../static/icons/social.png')
            
            a
                color: black

                &:focus
                    color: white
                    text-decoration: none

                &:hover
                    color: white
                    text-decoration: none

                &:hover span
                    background-position: bottom center

                &.active 
                    color: white
                    text-decoration: none
                    
                    span
                        background-position: bottom center

@media screen and ( max-width: $desktop )
    .internal-menu
        &__list
            &--item
                padding: 10px

                p
                    font-size: 15px

            .internal-menu-list
                span
                    width: 64px
                    height: 64px

@media screen and ( max-width: $tablet )
    .internal-menu
        &__list
            flex-wrap: wrap
            justify-content: center

            &--item
                width: calc(100% / 3)
                margin-bottom: 15px

@media screen and ( max-width: $phone )
    .internal-menu
        &__list
            flex-wrap: wrap
            justify-content: left

            &--item
                width: calc(100% / 2)
                margin: 0
                padding: 6px
                p
                    font-size: 14px

                img
                    width: 40%
                    margin-bottom: 10px
        &-icon
            display: none !important
