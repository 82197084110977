@import "../vars.sass"

.gallery-container
  display: grid
  grid-template-columns: repeat(4, 1fr)
  grid-gap: 5px
  margin: 20px 0

  &__item
    position: relative

    &--button
      border: none
      background: transparent
      padding: 0
      margin: 0

    &--img
      width: 100%
      
    &--span
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-color: rgba(227,195,66,0.6)
      display: none
    
    &:hover &--span
      display: block

.gallery-modal
  display: flex
  justify-content: center
  align-items: center
  position: fixed
  left: 0
  top: 0
  width: 100vw
  height: 100vh
  background-color: rgba(0, 0, 0, .8)
  z-index: 999
  
  &__wraper
    display: block
    width: 80%
    max-width: 1000px
    height: auto
    text-align: right

    &--btn
      border: 0
      background: transparent
      outline: none

      svg
        fill: rgba(239,207,48,0.7)
        height: 29px
        margin-top: 2px
      &:hover
        svg
          fill: rgba(239,207,48,1)

    &--content
      text-align: left

      img
        width: 100%
        max-height: 90vh
        object-fit: contain

  &__nav
    position: absolute
    width: 100%
    z-index: 1200

    ul
      margin: 0
      padding: 0

      li
        list-style-type: none
        display: inline-block
        width: 50%
        padding: 0 15px

        &:nth-child(2)
          text-align: right
        
        a
          img
            opacity: 0.65
        &:hover
          img
            opacity: 1

@media screen and ( max-width: $phone )
  .gallery-container
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 5px