@import "../vars.sass"

header
  h1
   margin: 40px 0 10px 0
   line-height: 1
  p
    margin: 0 0 20px 0

.package
  &__top
    h1
      text-align: center
  
  &__optios
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-gap: 40px
    margin: 40px 0 0 0

    > div
      background-color: $dark
      color: white
      padding: 5px 15px 15px 15px
      box-sizing: border-box

      h4
        text-align: center
      
      .enlace
        display: flex
        justify-content: center

  &__supporters
    background-color: $dark
    color: white
    padding: 5px 15px 15px 15px
    margin: 40px 0

    h4
      text-align: center

    .custom-list
      column-count: 2
      margin-bottom: 0

  &__gallery
    &--racers
      h1
        margin: 40px 0 20px 0
        text-align: center

@media screen and ( max-width: $tablet )
  .package__optios
    display: grid
    grid-template-columns: repeat(1, 1fr)