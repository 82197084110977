@import '../vars.sass'

.horizontal-form
  display: grid
  grid-template-columns: repeat(3, 1fr) 200px
  grid-gap: 20px
  margin: 20px 0

  input
    background-color: $yellow
    border: none
    padding: 8px
    width: 100%

    &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active
      -webkit-box-shadow: 0 0 0px 1000px $yellow inset
      -webkit-text-fill-color: $black

  ::placeholder
    color: $black

  :-ms-input-placeholder
    color: $black

  ::-ms-input-placeholder
    color: $black

  button
    background: $black
    color: white
    border: none

    &:hover
      background-color: $yellow
      color: $black

    &.btn-thanks
      background: $whatsapp

  .btn-fill
    background-color: $red
    color: $white

    &:hover
      background-color: $red
      color: $white

    &::placeholder
      color: $white

    &:-ms-input-placeholder
      color: $white

    &::-ms-input-placeholder
      color: $white

@media screen and ( max-width: $desktop )
  .horizontal-form
    grid-template-columns: repeat(3, 1fr) 120px

@media screen and ( max-width: $tablet )
  .horizontal-form
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: repeat(2, 30px)