@import '../vars.sass'

.Modal
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(0, 0, 0, .7)
  z-index: 10
  &-wrapper
    background-color: white
    width: 100%
    max-width: 380px
    padding: 20px 30px 10px 30px
    box-sizing: border-box
    &-header
      text-align: right
      button
        border: none
        background: transparent
        font-size: 18px
      &:hover
        color: $yellow
    &-body
      img
        display: block
        width: 100px
        margin: 0 auto
      h2
        text-align: center
      p
        text-align: justify

  .register-form
    input
      background-color: $yellow
      border: none
      width: 100%
      color: black
      margin-bottom: 15px
      padding: 10px

      &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active
        -webkit-box-shadow: 0 0 0px 1000px $yellow inset
        -webkit-text-fill-color: $black

    ::placeholder
      color: $black

    :-ms-input-placeholder
      color: $black

    ::-ms-input-placeholder
      color: $black

    select
      background-color: $yellow
      border: none
      width: 100%
      color: black
      margin-bottom: 15px
      padding: 10px

    button
      display: block
      width: 100%
      background-color: #000
      color: #FFF
      border: none
      margin-bottom: 15px
      padding: 10px

      &:hover
        background-color: $yellow
        color: #000
        
      &.btn-thanks
        background: $whatsapp

    .btn-fill
      background-color: $red
      color: $white

      &:hover
        background-color: $red
        color: $white

      &::placeholder
        color: $white

      &:-ms-input-placeholder
        color: $white

      &::-ms-input-placeholder
        color: $white

@media screen and ( max-width: $phone )
  .Modal
    overflow-y: scroll
    align-items: flex-start