@import '../vars.sass'

.whats-included
  &__banner
    border-top: 5px solid #f1d130
    border-bottom: 5px solid #f1d130

  &__title
    margin: 40px 0

  &-nav
    border: none
    display: grid
    width: 100%
    grid-template-columns: repeat(4, 1fr)
    grid-gap: 2px
    list-style-type: none
    margin: 0
    padding: 0

    > li
      width: 100%
      text-align: center
      margin-bottom: 0
      background-color: $yellow
      text-transform: uppercase
      display: flex
      align-items: center
      justify-content: center

      &:hover, &:hover > a
        border: 0
        background-color: $black
        color: $white
        text-decoration: none

      &.active
        border: 0
        background-color: $black
        color: $white

      &.active > a
        border: 0
        background-color: $black
        color: $white
        &:hover, &:focus
          background: transparent
          border: none
          color: $white
          text-decoration: none

      > a
        font-family: 'helveticaneuebold'
        color: $black
        border: 0
        border-radius: 0
        display: block
        width: 100%
        padding: 10px 5px

  .back-dark
    background-color: $dark
    color: $white
    padding: 20px 30px 10px 30px

  h2
    margin: 0