@import "../vars.sass"

.stages
    margin: 20px 0

    &__img--resumen
        margin-bottom: 20px
    
    &__statics
        margin: 0 0 10px 0
        padding: 0
        display: flex

        li
            list-style-type: none
            font-weight: bold
            padding: 0 15px

            span
                display: block
                color: $yellow

.info-stage
    margin: 0
    padding: 0
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: repeat(2,span 1)
    grid-gap: 40px
    align-items: center

    li
        list-style-type: none
        text-align: justify

    h3
        margin: 0 0 10px 0
    
    b
        display: block
    
    &__img
        width: 100%


@media screen and ( max-width: $desktop )
    .stages__statics
        flex-wrap: wrap

        li
            width: calc( 100% / 5 )

@media screen and ( max-width: $phone )
    .info-stage
        grid-template-columns: repeat(1, 1fr)

    .stages__statics
        li
            width: calc( 100% / 4 )