@import '../vars.sass'

.new-detail
  border-top: 5px solid #f1d130
  &__title
    font-size: 30px
  &__hashtag
    font-size: 24px
    margin: 0
    span
      color: $red
  &__date
    font-size: 15px
    margin: 0 0 10px 0
  &__content
    text-align: justify
  &__button
    border: none
    font-size: 20px
    color: #000
    background-color: #efcf30
    font-weight: bold
    padding: 6px 10px
    &:hover
      background-color: $black
      color: $white
  &__image
    overflow: hidden
    position: relative
    div
      position: absolute
      width: 100%
      left: 0
      bottom: 0
      padding: 8px 10px 0 10px
      background-color: rgba(0,0,0,0.6)
      font-family: 'helveticaneuemedium'
      font-weight: bold
      -moz-osx-font-smoothing: grayscale
      color: #FFF
      p
        margin: 0
        span
          color: $red
    img
      transition: 0.5s
      -webkit-transition: 0.5s
      &:hover
        -webkit-transform: scale(1.1)
        transform: scale(1.1)
        transition: 0.5s
        -webkit-transition: 0.5s
    &--mb
      margin-bottom: 19px
  &__recomendations
    margin-bottom: 40px
  &__facebook
    margin-top: 40px

@media screen and ( max-width: $phone )
  .new-detail__image--onlyxsmb
    margin-bottom: 19px