@import "./vars.sass"

body
  font-family: 'helveticaneuemedium'
  color: #424242
  position: relative

*:focus
  outline: none

h1
  font-size: 32px

.min-height
  min-height: 700px

.mt-4
  margin-top: 1.5rem !important

.no-padding
  padding: 0

.contenido_min
  min-height: 650px

#contenido
  min-height: 700px

#contenido_video
  min-height: 436px

.background-dark
  background-color: $dark
  color: $white

.enlace
  display: inline-block

  a
    display: block
    color: #000
    background-color: rgba(239,207,48,1)
    font-weight: bold
    padding: 6px 10px
    text-decoration: none
  
    &:hover
      color: #fff
      background-color: #000

.yellow-link
  color: $yellow
  &:hover
    color: $black
    text-decoration: none

.black-link
  color: $black
  font-weight: bold
  white-space: nowrap
  &:hover
    color: $yellow
    text-decoration: none

.custom-list
  margin: 0 0 10px 0
  padding: 0 0 0 15px
  li
    list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAFVJREFUeNpiZEACHy8ZOECZF/j1LnwAMRihEgJAaj8QG0AVgCQdgYouMEEFEpAkQQCkoR7EYEISQAcCyAoOYFFwEO4GqDsCoMaCdC4E2t/AQBUAEGAA344SyoGa8hIAAAAASUVORK5CYII=")
    p
      margin: 0
    b
      color: $yellow

.custom
  display: flex
  flex-direction: row
  flex-wrap: wrap
  justify-content: space-between
  align-items: center
  margin: $space 0

  > div
    width: 48%
    text-align: justify

    img
      width: 100%

.table_leyenda
  tr
    th
      background-color: rgba(239,207,48,0.7)
      font-weight: bold
      text-align: center

  tr:nth-child(odd)
    background-color: rgba(214, 214, 214, 0.5)

  .min
    width: 20%

@media screen and (max-width: 970px)
  .custom
    flex-direction: column
    margin: 20px 0 0 0

    &.left
      flex-direction: column-reverse

    div > h1
      margin-top: 0

    div > h4
      margin-top: 0

    > div
      width: 100%
      margin-bottom: 20px