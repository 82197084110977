@import "../vars.sass"

.routes
  &__img--resumen 
    margin-bottom: 20px
  
  &__statics
    margin: 20px 0 0 0
    padding: 0
    display: flex
    justify-content: space-between

    p
      margin: 0

    li
      list-style-type: none
      font-weight: bold

      span
        display: block
        color: $yellow

.route-banner
  padding: 0
  border-top: 5px solid #f1d130
  border-bottom: 5px solid #f1d130

  img
    width: 100%

@media screen and (max-width: 970px)
  .custom
    flex-direction: column
    margin: 0
    &.left
      flex-direction: column-reverse
    > div
      width: 100%
      margin-bottom: 20px

@media screen and ( max-width: $desktop )
  .routes__statics
    flex-wrap: wrap
    justify-content: flex-start

    li
      width: calc( 100% / 5 )
      margin-bottom: 20px

@media screen and ( max-width: $phone )
  .routes__statics
    li
      width: calc( 100% / 3 )
