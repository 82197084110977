@import '../vars'

.spectators
    margin: 40px 0

    &__list
        margin: 0
        padding: 0
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-gap: 15px

        &--item
            list-style-type: none
            margin: 0
            padding: 0

            h3
                margin: 0 0 15px 0

@media screen and ( max-width: $phone )
    .spectators
        &__list
            grid-template-columns: 1fr
    