@font-face
  font-family: 'iron__brineregular'
  src: url('../fonts/iron__brine/iron__brine-webfont.woff2') format('woff2')
  src: url('../fonts/iron__brine/iron__brine-webfont.woff') format('woff')
  src: url('../fonts/iron__brine/iron__brine-webfont.ttf') format('truetype')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'helveticaneuebold'
  src: url('../fonts/helvetica/helvetica_neu_bold-webfont.woff2') format('woff2'), url('../fonts/helvetica/helvetica_neu_bold-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'helveticaneuemedium'
  src: url('../fonts/helvetica/helveticaneue_medium-webfont.woff2') format('woff2'),url('../fonts/helvetica/helveticaneue_medium-webfont.woff') format('woff')
  font-weight: normal
  font-style: normal

$space: 40px

$yellow: #f1d130
$dark: #424242
$black: #000000
$red: #ff0000
$green: #85BB65
$orange: #FF9300
$white: #EBEBEB
$whatsapp: #25D366

$home: #046307
$home-bg-dark: #F2F3F3
$cali: #0047BB
$caldas: #088A30
$volcan: #FC4C02
$zipaquira: #008b2d
$manizales: #E10600
$lavega: #FC4C02
$prox1: #E10600
$prox2: #319b42
$prox3: #FFA300
$grey: #d6d6d6
$cafe: #C8AD90
$light-blue: #87CEEB
$orange_series: #FF4500
$font: #424242

$caribe: #CD1226
$series: #307fe2

$big_desktop: 1200px
$desktop: 992px
$tablet: 768px
$phone: 576px
$sm_phone: 430px
