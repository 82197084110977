.faqs
  &__main-title
    margin: 40px 0
    text-transform: uppercase
  
  .panel-group
    margin-bottom: 40px

  .panel-default > .panel-heading
      background-color: rgba(239,207,48,0.7)
