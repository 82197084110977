@import "../vars.sass"

.table-results
  display: grid
  grid-template-columns: 12% 12% 17% 9% 30% 10% 10%
  margin-bottom: 30px

  div
    display: flex
    flex-direction: column
    justify-content: center
    border-top: 1px solid #ddd
    padding: 8px

  &__title
    background-color: $yellow
    font-weight: bold
    color: $black

  &__pos-category
    text-align: center

  &__pos-overall
    text-align: center

  &__number
    text-align: center
    p
      margin: 3px 0 10px 0

  &__racer
    p
      margin: 0

  &__time
    p
      margin: 3px 0 10px 0

  &__category
    text-transform: uppercase

.table-results-mobile
  &__item
    padding: 5px 0

    &:nth-child(even)
      background-color: rgba(214, 214, 214, 0.5)

    &--racer
      display: block

.racer
  &__red
    color: red

.ptop
  padding-top: 7px

@media screen and ( max-width: $big_desktop )
  .table-results
    font-size: 13px

@media screen and ( max-width: $desktop )
  .table-results
    font-size: 12px

    &__title
      font-size: 11px

    &__racer
      img
        width: 22px

@media screen and ( max-width: $phone )
  .table-results
    grid-template-columns: 1fr
    text-align: left
