@import '../vars.sass'

.contact-form
  margin: 40px 0

  input
    background-color: $yellow
    border: none
    width: 100%
    color: black
    margin-bottom: 15px
    padding: 10px

    &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active
      -webkit-box-shadow: 0 0 0px 1000px $yellow inset
      -webkit-text-fill-color: $black

  ::placeholder
    color: $black

  :-ms-input-placeholder
    color: $black

  ::-ms-input-placeholder
    color: $black

  select
    background-color: $yellow
    border: none
    width: 100%
    color: black
    margin-bottom: 15px
    padding: 10px

  textarea
    background-color: $yellow
    border: none
    width: 100%
    color: black
    margin-bottom: 15px
    padding: 10px

  button
    width: 100%
    background-color: #000
    border: none
    color: $white
    margin-bottom: 15px
    padding: 10px

    &:hover
      background-color: $yellow
      color: #000
      
    &.btn-thanks
      background: $whatsapp

  .btn-fill
    background-color: $red
    color: $white

    &:hover
      background-color: $red
      color: $white

    &::placeholder
      color: $white

    &:-ms-input-placeholder
      color: $white

    &::-ms-input-placeholder
      color: $white

.team
  margin: 0
  padding: 0

  li
    list-style-type: none
    display: inline-block
    width: 20%
    margin: 2%

    div
      background-color: $yellow
      padding: 10px
      box-sizing: border-box

      h3
        border-bottom: 1px solid #000
        margin: 5px 0
        padding: 0
        font-size: 21px

      p
        margin: 0

@media screen and ( max-width: $tablet )
  .team
    li
      width: 46%
      margin: 2%

@media screen and ( max-width: $phone )
  .team li div
    h3
      font-size: 16px

@media screen and ( max-width: $sm_phone )
  .team li div
    h3
      font-size: 13px